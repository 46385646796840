import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ArrowNext from "./CustomArrows/ArrowNext";
import ArrowPrev from "./CustomArrows/ArrowPrev";

import "./SliderWorkers.scss";

interface SliderWorkersProps {
  children: React.ReactNode;
}

const SliderWorkers = ({ children }: SliderWorkersProps) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return <Slider {...settings}>{children}</Slider>;
};

export default SliderWorkers;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { SxProps, Theme } from "@mui/material";

import { faViber } from "@fortawesome/free-brands-svg-icons";

import cl from "./Footer.module.scss";

const Footer = () => {
  const sxPropNetworks: SxProps<Theme> = {
    color: "#808080",
    fontSize: "2rem",
    transition: "all 0.5s ease",
    cursor: "pointer",
    "&:hover": {
      color: "#fff",
    },
  };

  const phoneNumber = "79823124835";
  const email = "info@otr-it.ru";
  const emailHeader = "Запрос на разработку IT решений";

  return (
    <footer>
      <div className={cl.footer}>
        <div className={cl.networks}>
          {/*<a href={`tel:${phoneNumber}`} className={cl.network}>*/}
          {/*  <LocalPhoneIcon sx={sxPropNetworks} />*/}
          {/*</a>*/}
          {/*<a target="_blank" href={`mailto:${email}?subject=${emailHeader}`} className={cl.network} rel="noreferrer">*/}
          {/*  <AlternateEmailIcon sx={sxPropNetworks} />*/}
          {/*</a>*/}
          {/*<a href={`https://t.me/+${phoneNumber}`} target="_blank" className={cl.network} rel="noreferrer">*/}
          {/*  <TelegramIcon sx={sxPropNetworks} />*/}
          {/*</a>*/}
          {/*<a href={`viber://contact?number=%2B${phoneNumber}`} target="_blank" className={cl.network} rel="noreferrer">*/}
          {/*  <FontAwesomeIcon className={cl.faIcon} size="2x" icon={faViber} />*/}
          {/*</a>*/}
          {/*<a href={`https://api.whatsapp.com/send?phone=${phoneNumber}`} target="_blank" className={cl.network} rel="noreferrer">*/}
          {/*  <WhatsAppIcon sx={sxPropNetworks} />*/}
          {/*</a>*/}
        </div>

        <div className={cl.copyrightInfo}>
          ИНН <span className={cl.copyrightNumbers}>7453346521</span> КПП <span className={cl.copyrightNumbers}>745301001</span>
          <span className={cl.separator}> </span>
          ОГРН <span className={cl.copyrightNumbers}>1227400017789 454080</span>,<span className={cl.separator}> </span>
          Челябинская область, г Челябинск,
          <span className={cl.separator}> </span>
          Лесопарковая ул, д. <span className={cl.copyrightNumbers}>8</span>, помещ. <span className={cl.copyrightNumbers}>1423</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import "./App.module.scss";
import MainRouter from "./Components/MainRouter/MainRouter";

function App() {
  return (
    <div className="App">
      <MainRouter />
    </div>
  );
}

export default App;

import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "../Layout/Layout";
import MainPage from "../MainPage/MainPage";

const MainRouter = () => {
  return (
    <Routes>
      <Route path="/main" element={<Layout />}>
        <Route index element={<MainPage />} />
      </Route>
      <Route path="*" element={<Navigate to={"/main"} />} />
    </Routes>
  );
};

export default MainRouter;

import { Outlet } from "react-router-dom";

import Footer from "./Footer/Footer";

import Header from "./Header/Header";
import cl from "./Layout.module.scss";

const Layout = () => {
  return (
    <div className={cl.layoutWrapper}>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;

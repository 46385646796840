import { useContext, useEffect, useRef } from "react";
import { HashLink } from "react-router-hash-link";

import { LandingPageContext } from "../../../context/LandingContext";

import { SectionId } from "../../../types/Section.types";

import cl from "./Header.module.scss";

const Header = () => {
  interface INavigator {
    name: string;
    sectionId: SectionId;
  }
  const navigators: INavigator[] = [
    { name: "Главная", sectionId: "#home" },
    { name: "Услуги", sectionId: "#services" },
    { name: "CEO", sectionId: "#ceo" },
    { name: "Сотрудники", sectionId: "#workers" },
    { name: "Разработки", sectionId: "#projects" },
  ];
  const navigationRef = useRef<HTMLDivElement>(null!);
  const { currentNavigation, setCurrentNavigation } = useContext(LandingPageContext);

  useEffect(() => {
    const onScrollColorMenu = (e: Event) => {
      const scrolled = window.scrollY;
      if (scrolled !== 0) {
        navigationRef.current.classList.add(cl.scrolledNavigation);
      } else {
        navigationRef.current.classList.remove(cl.scrolledNavigation);
      }
    };
    window.addEventListener("scroll", onScrollColorMenu);
    return () => {
      window.removeEventListener("scroll", onScrollColorMenu);
    };
  }, []);
  return (
    <div ref={navigationRef} className={cl.navigationWrapper}>
      <div className={cl.logo}>ОТР-ИТ</div>
      <nav className={cl.navigation}>
        {navigators.map((navigator) => (
          <HashLink
            key={navigator.sectionId}
            smooth
            to={navigator.sectionId}
            onClick={() => {
              setCurrentNavigation(navigator.sectionId);
            }}
            className={`${cl.navigation__item}
             ${currentNavigation === navigator.sectionId ? cl.active : ""}`}
          >
            {navigator.name}
          </HashLink>
        ))}
      </nav>
    </div>
  );
};

export default Header;

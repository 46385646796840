import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { LandingPageContextProvider } from "./context/LandingContext";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LandingPageContextProvider>
        <App />
      </LandingPageContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

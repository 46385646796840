import { createContext, useState } from "react";

import { LandingContext } from "../types/LandingContext.types";
import { SectionId } from "../types/Section.types";

export const LandingPageContext = createContext<LandingContext>(null!);

export const LandingPageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentNavigation, setCurrentNavigation] = useState<SectionId>("#home");

  return <LandingPageContext.Provider value={{ currentNavigation, setCurrentNavigation }}>{children}</LandingPageContext.Provider>;
};

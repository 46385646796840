import { sliderWorkerType } from "../../../types/sliderWorker";
import DescriptionText from "../../Utils/DescriptionText/DescriptionText";

import cl from "./WorkerCard.module.scss";

const WorkerCard = ({ imgName, header, description }: sliderWorkerType) => {
  return (
    <div className={cl.wrapper}>
      <picture>
        <source srcSet={require(`../../../imgs/workers/${imgName}/${imgName}.avif`)} type="image/avif" />
        <source srcSet={require(`../../../imgs/workers/${imgName}/${imgName}.webp`)} type="image/webp" />
        <img src={require(`../../../imgs/workers/${imgName}/${imgName}.png`)} className={cl.sliderImg} alt="worker" />
      </picture>
      <div className={cl.textContent}>
        <h3 className={cl.sliderHeader}>{header}</h3>
        <DescriptionText fontWeight="300" margin="0 0 0 5px" color="#fff">
          {description}
        </DescriptionText>
      </div>
    </div>
  );
};

export default WorkerCard;

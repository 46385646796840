import { sliderWorkerType } from "../types/sliderWorker";

export const sliderWorkers: sliderWorkerType[] = [
  {
    imgName: "Грязнов_Алексей_custom",
    header: "Грязнов Алексей Витальевич",
    description: "CTO",
  },
  {
    imgName: "Мальчихин_Дмитрий_custom",
    header: "Мальчихин Дмитрий Геннадьевич",
    description: "Head of Financial Sector Development",
  },
  {
    imgName: "Нестеров_Андрей_custom",
    header: "Нестеров Андрей Николаевич",
    description: "QAD - Engineer",
  },
  {
    imgName: "Устюжанинов_Александр_custom",
    header: "Устюжанинов Александр Валентинович",
    description: "System Administrator",
  },
  {
    imgName: "Плотников_Егор_custom",
    header: "Плотников Егор Евгеньевич",
    description: "Tester, Technical Support Engineer",
  },
  {
    imgName: "Тупцикий_Иван_custom",
    header: "Тупцикий Иван Евгеньевич",
    description: "Technical Support Engineer",
  },
  {
    imgName: "Черепанов_Павел_Александрович_custom",
    header: "Черепанов Павел Александрович",
    description: "BackEnd developer",
  },
  {
    imgName: "Озеров_Максим_Игоревич_custom",
    header: "Озеров Максим Игоревич",
    description: "Head of Android Development Studio",
  },
  {
    imgName: "Яннуров_Вадим_Рафаилович_custom",
    header: "Яннуров Вадим Рафаилович",
    description: "Android developer",
  },
  {
    imgName: "Катаев_Егор_Константинович_custom",
    header: "Катаев Егор Константинович",
    description: "Backend developer (Microservice Architecture)",
  },
  {
    imgName: "Ращупкин_Евгений_Владимирович_custom",
    header: "Ращупкин Евгений Владимирович",
    description: "DevOps",
  },
  {
    imgName: "Коротовский_Дмитрий_Сергеевич_custom",
    header: "Коротовский Дмитрий Сергеевич",
    description: "Design and Development of User Interfaces for Desktop Applications",
  },
  {
    imgName: "Эстпан_Мохаммад_Магди_Сайед_custom",
    header: "Эстпан Мохаммад Магди Сайед",
    description: "Network Engineer",
  },
];

import DescriptionText from "../../Utils/DescriptionText/DescriptionText";

import { AboutCardType } from "../../../types/AboutCard.types";

import cl from "./AboutCard.module.scss";

type IAboutCardProps = Omit<AboutCardType, "id">;

const AboutCard = ({ title, description, imgName }: IAboutCardProps) => {
  return (
    <div className={cl.cardWrapper}>
      <picture>
        <source srcSet={require(`../../../imgs/iconsAboutCard/${imgName}/${imgName}.avif`)} type="image/avif" />
        <source srcSet={require(`../../../imgs/iconsAboutCard/${imgName}/${imgName}.webp`)} type="image/webp" />
        <img className={cl.cardIcon} src={require(`../../../imgs/iconsAboutCard/${imgName}/${imgName}.png`)} alt={title} />
      </picture>
      {title ? <h3 className={cl.headerText}>{title}</h3> : null}
      <div className={cl.descriptionWrapper}>
        <DescriptionText fontSize="1.3rem" textAlign="center">
          {description}
        </DescriptionText>
      </div>
    </div>
  );
};

export default AboutCard;

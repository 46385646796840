import { styled } from "@mui/material/styles";
interface IWhiteTextProps {
  color?: string;
  lineHeight?: string;
  fontSize?: string;
  fontWeight?: string;
  margin?: string;
  textAlign?: "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start";
}

const StyledDescriptionText = styled("div")<IWhiteTextProps>(({ color = "#fff", lineHeight = "24px", fontSize = "1rem", fontWeight = "350", margin = "0", textAlign = "left" }) => ({
  color,
  fontWeight,
  lineHeight,
  fontSize,
  margin,
  textAlign,

  "@media (max-width: 1200px)": {
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "24px",
  },
}));

const DescriptionText = ({ children, ...props }: { children?: React.ReactNode } & IWhiteTextProps) => {
  return <StyledDescriptionText {...props}>{children}</StyledDescriptionText>;
};

export default DescriptionText;

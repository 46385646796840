import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { ArrowProps } from "./ArrowProps.types";

const ArrowPrev = (props: ArrowProps) => {
  const { className, style, onClick } = props;

  return (
    <div className={className} style={{ ...style, left: "-37px" }} onClick={onClick}>
      <ArrowBackIosNewIcon sx={{ fill: "#090975", fontSize: "2rem" }} />
    </div>
  );
};

export default ArrowPrev;

import { ProjectCardsType } from "../../../types/projectCard.types";

import cl from "./ProjectCard.module.scss";

export const ProjectCard = ({ imgName, title, children }: ProjectCardsType) => {
  return (
    <div className={cl.projectCardWrapper}>
      <picture>
        <source srcSet={require(`../../../imgs/projectImages/${imgName}/${imgName}.avif`)} type="image/avif" />
        <source srcSet={require(`../../../imgs/projectImages/${imgName}/${imgName}.webp`)} type="image/webp" />
        <img className={cl.projectImg} src={require(`../../../imgs/projectImages/${imgName}/${imgName}.png`)} alt={title} />
      </picture>
      <div className={cl.projectInfo}>
        <div className={cl.projectHeader}>{title}</div>
        <div className={cl.projectDescription}>{children}</div>
      </div>
    </div>
  );
};

import { useContext, useEffect, useRef } from "react";

import { LandingPageContext } from "../context/LandingContext";
import { SectionId } from "../types/Section.types";

const useObserver = (navigators: React.MutableRefObject<HTMLElement | HTMLDivElement>[]) => {
  const observerRef = useRef<IntersectionObserver>(null!);
  const { setCurrentNavigation } = useContext(LandingPageContext);
  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentNavigation(`#${entry.target.id}` as SectionId);
          }
        });
      },
      { threshold: [0.6, 0.9] },
    );
    navigators.forEach((navigator) => {
      observerRef.current.observe(navigator.current);
    });

    return () => {
      observerRef.current.disconnect();
    };
  });
};

export default useObserver;

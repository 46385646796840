import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { ArrowProps } from "./ArrowProps.types";

const ArrowNext = (props: ArrowProps) => {
  const { className, style, onClick } = props;

  return (
    <div className={className} style={{ ...style, right: "-25px" }} onClick={onClick}>
      <ArrowForwardIosIcon sx={{ fill: "#090975", fontSize: "2rem" }} />
    </div>
  );
};

export default ArrowNext;
